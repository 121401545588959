.Navbar {
  position: fixed;
  background-color: #272727;
  top: 0;
  left: 0;
  width: 80vw;
  padding-top: 10px;
  height: 50px;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  z-index: 1;
  justify-content: space-around;
}

.Navbar .linkInstance {
  text-decoration: none;
  color: #AAAAAA;
  margin-bottom: 12px;
  line-height: 2.2;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
}

.Navbar .linkInstance:hover {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  background-color: #333333;
}

.Navbar .active {
  pointer-events: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  background-color: #404040;
}

.Navbar .animation {
  pointer-events: none;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  background-color: #606060;
}
