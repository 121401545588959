.Games {
  margin-top: 100px;
}

.Games .title {
  font-weight: 500;
  margin-bottom: 0px;
}

.Games .instance {
  display: inline-block;
  margin-top: 10px;
  flex-direction: column;
  height: 200px;
}

.Games .outsideLink {
  display: inline-block;
  margin-top: 60px;
}

.Games .projectLink {
  display: inline-block;
  border-radius: 12px;
  background-color: #272727;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  color: #AAAAAA;
  margin-bottom: 20px;
}

.Games .projectLink:hover {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  background-color: #333333;
}

.Games .description {
  margin-top: 5px;
  margin-bottom: 25px;
}

.Games .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

}

