.Sites {
  margin-top: 50px;
}

.Sites .title {
  font-weight: 500;
  margin-bottom: 0px;
}

.Sites .instance {
  display: inline-block;
  margin-top: 30px;
  flex-direction: column;
  height: 200px;
}

.Games .outsideLink {
  display: inline-block;
  margin-top: 60px;
}

.Sites .projectLink {
  display: inline-block;
  border-radius: 12px;
  background-color: #272727;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  color: #AAAAAA;

}

.Sites .projectLink:hover {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  background-color: #333333;
}

.Sites .description {
  margin-top: 25px;
}

.Sites .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

}

