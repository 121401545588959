.title {
  font-size: 300%;
  width: 50vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  margin-bottom: 20px;;
}

.myWork .desc {
  margin-top: 0;
  font-size: 125%;
}

.myWork {
  display: flex;
  flex-direction: column;
}

.connections {
  margin-top: 20px;
  font-size: 100%;
}

.connections .instance {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.connections .logo {
  width: 60px;
  height: auto;
}